export default {
  getProject: "/aps/project/project", // 查询项目

  getProjectTree: "/aps/project/getProjectTree",

  getBomList: "/aps/project/getBomList",

  getProcessCategoryCabinet: "/aps/process/categoryCabinet/all",

  getRouteCabinet: "/aps/process/categoryCabinet/getRouteCabinet",

  insertProcessCategoryCabinet: "/aps/process/categoryCabinet/insert",
};
