<template>
  <div style="display: flex; height: 610px; flex-direction: row; justify-content: space-between">
    <project-tree
      :data-tree="dataTree"
      :project="project"
      :height="390"
      :width="240"
      :max-width="120"
      :show-select="false"
      style="padding-right: 30px"
      @handleNodeClick="handleNodeClick"
    ></project-tree>
    <div style="border: 1px solid #dcdfe6; overflow-y: auto" class="process-category-content">
      <div
        style="
          display: flex;
          padding: 10px;
          border-bottom: 1px solid #dcdfe6;
          font-size: 14px;
          color: #606266;
        "
      >
        <!-- <div style="padding-right: 50px; width: 220px" class="ellipsis" :title="selectedData.name">
          柜号：{{ selectedData.style == 50 ? selectedData.name : "--" }}
        </div>
        <div
          style="padding-right: 50px; width: 150px"
          class="ellipsis"
          :title="selectedData.cabinetCode"
        >
          柜名：{{ selectedData.style == 50 ? selectedData.cabinetCode : "--" }}
        </div>
        <div
          style="padding-right: 50px; width: 150px"
          class="ellipsis"
          :title="selectedData.typeName"
        >
          箱柜类型：{{ selectedData.style == 50 ? selectedData.typeName : "--" }}
        </div>
        <div style="padding-right: 50px; width: 150px" class="ellipsis">
          数量：{{ selectedData.style == 50 ? selectedData.count : "--" }}
        </div> -->
        <div style="font-weight: 700">bom汇总</div>
      </div>
      <el-table
        :data="bomData"
        row-key="id"
        border
        :default-expand-all="false"
        :span-method="spanMethod"
        :cell-class-name="getRowClass"
        style="width: 100%; cursor: pointer"
      >
        <el-table-column label="顺序" width="80" type="index" align="center"></el-table-column>
        <el-table-column prop="bomCategoryId" label="分类" width="80">
          <template slot-scope="scope">
            <span>
              {{ scope.row.bomCategoryName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="model" label="型号" width="240"></el-table-column>
        <el-table-column prop="unit" label="单位" width="70"></el-table-column>
        <el-table-column prop="count" label="数量" width="70">
          <template slot-scope="scope">
            <span>
              {{ scope.row.count.toFixed(2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="80"></el-table-column>
      </el-table>
    </div>
    <div
      style="border: 1px solid #dcdfe6; overflow-y: auto; margin-left: 10px"
      class="process-category-content"
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid #dcdfe6;
          font-size: 14px;
          color: #606266;
        "
      >
        <div style="font-weight: 700">工艺</div>
        <div style="font-weight: 700; padding-left: 30px; color: red">
          <span>总工时:</span>
          <span>{{ totalHours.toFixed(2) }}h</span>
        </div>
      </div>
      <el-table
        :data="processData"
        row-key="id"
        border
        :span-method="spanMethodFlow"
        :default-expand-all="false"
        style="width: 100%; cursor: pointer"
      >
        <el-table-column label="序号" width="80" type="index" align="center"></el-table-column>
        <el-table-column prop="bomCategoryId" label="分类" width="80">
          <template slot-scope="scope">
            <span>
              {{ scope.row.bomCategoryName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="名称"
          width="280"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="processNum" label="数量" width="70">
          <template slot-scope="scope">
            <span>
              {{ scope.row.processNum }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="工时(h)" width="70">
          <template slot-scope="scope">
            <span>
              {{ getMinute(scope.row) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" width="70">
          <template slot-scope="scope">
            <span>
              {{ (scope.row.processNum * scope.row.price).toFixed(2) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="80"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { getProjectTreeFun } from "@/utils/util";
import ProjectTree from "../../components/ProjectTree";
import Project from "@/api/project";
import BomCategory from "@/api/bom-category";
export default {
  name: "ScheduleList",
  components: { ProjectTree },
  props: {
    project: { type: Object, default: {} },
  },
  data() {
    return {
      selectedData: {},
      dataTree: [],
      bomData: [],
      queryBom: {
        oldPage: 1,
        page: 1,
        pageSize: 130,
        searchInfo: "",
        orderByColumn1: "id",
        isAsc1: "asc",
      },
      processFlow: [],
      processData: [],
      totalHours: 0,
      totalPrice: 0,
    };
  },
  mounted() {},
  methods: {
    onOpen() {
      this.getProjectTree();
    },
    handleNodeClick(data) {
      // this.tabledata = data;
      this.selectedData = data;
      const params = this.getParam(data);
      this.getProjectBom(params);
    },
    getParam(data) {
      const param = {};
      if (data && data.hasOwnProperty("id")) {
        if (this.isCategory(data)) {
          param.categoryId = data.id;
        } else {
          param.cabinetId = data.id;
        }
      }
      return param;
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.property === "bomCategoryId") {
        if (rowIndex > 0 && row.bomCategoryId === this.bomData[rowIndex - 1].bomCategoryId) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
        let rowspan = 1;
        for (let i = rowIndex + 1; i < this.bomData.length; i++) {
          if (this.bomData[i].bomCategoryId === row.bomCategoryId) {
            rowspan++;
          } else {
            break;
          }
        }
        return {
          rowspan,
          colspan: 1,
        };
      }
    },
    spanMethodFlow({ row, column, rowIndex, columnIndex }) {
      if (column.property === "bomCategoryId" || column.property === "price") {
        if (rowIndex > 0 && row.bomCategoryId === this.processData[rowIndex - 1].bomCategoryId) {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
        let rowspan = 1;
        for (let i = rowIndex + 1; i < this.processData.length; i++) {
          if (this.processData[i].bomCategoryId === row.bomCategoryId) {
            rowspan++;
          } else {
            break;
          }
        }
        return {
          rowspan,
          colspan: 1,
        };
      }
    },
    getProjectBom(params) {
      // 获取菜单列表
      const that = this;
      params.projectId = that.project.id;
      params.projectCreateDate = that.project.createTime;
      params.pageSize = this.queryBom.pageSize;
      params.pageNum = this.queryBom.page;
      params.orderByColumn1 = "id";
      params.isAsc1 = "asc";
      params.summary = true;
      that.axios.post(Project.getBomList, params).then((res) => {
        this.bomData = JSON.parse(res).rows;
      });
    },
    // 查询设备树
    getProjectTree() {
      const that = this;
      const params = {
        projectId: that.project.id,
        projectCreateDate: that.project.createTime,
      };

      that.axios.post(Project.getProjectTree, params).then((res) => {
        const data = JSON.parse(res);
        this.cabinetList = data.data.cabinetList;
        this.loadTree(data.data);
      });

      // 查询bom
      // that.getProjectBom({});
      that.getBomCategory();
    },
    getBomCategory() {
      const that = this;
      const params = {};
      params.projectId = that.project.id;
      params.projectCreateDate = that.project.createTime;
      params.pageSize = this.queryBom.pageSize;
      params.pageNum = this.queryBom.page;
      params.orderByColumn1 = "name";
      params.isAsc1 = "asc";
      params.summary = true;
      this.totalHours = 0;
      this.totalPrice = 0;
      that.axios.post(BomCategory.getBomMatchingList, params).then((res) => {
        that.processData = res.processFlowMatching;
        that.bomData = res.bomInfoList;
        const nonZeroValues = that.bomData.filter((item) => item.bomCategoryId !== 0);
        nonZeroValues.sort((a, b) => a.bomCategoryId - b.bomCategoryId);
        const zeroValues = that.bomData.filter((item) => item.bomCategoryId === 0);
        that.bomData = [...nonZeroValues, ...zeroValues];

        that.processData.forEach((row) => {
          if (row.processNum && row.processNum.toString().indexOf(".") >= 0) {
            row.processNum = parseFloat(row.processNum).toFixed(2);
          }
          this.totalHours += (row.auxiliaryMinute + row.basicMinute) * row.processNum;
        });
      });
    },
    getMinute(row) {
      let total = (row.auxiliaryMinute + row.basicMinute) * row.processNum;
      if (total && total.toString().indexOf(".") >= 0) {
        total = parseFloat(total).toFixed(2);
      }
      return total;
    },
    isCategory(data) {
      return data && data.style === 100;
    },
    loadTree(data) {
      if (data) {
        this.dataTree = getProjectTreeFun(
          data,
          this.GLOBAL.NODE_TYPE,
          this.project.name,
          this.project.code,
          false
        );
      }
    },

    getRowClass({ row }) {
      if (row.isMatch === 1) {
        return "setClass";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.workshop-content {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 210px);
  border: 1px solid #e9ecf0;
}

.lead-gante-gc-box {
  overflow: auto;
  top: 0;
  border-left: 5px solid transparent;
  height: 100%;
  box-sizing: border-box;
  padding-right: 2px;
}
</style>

<style>
.setClass {
  color: red !important;
}
</style>
