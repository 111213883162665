<template>
  <div
    class="project-tree"
    :style="'width: ' + width + 'px'"
    style="height: 100%; display: flex; flex-direction: column"
  >
    <el-input
      v-model="treeNodeName"
      placeholder="输入设备编号"
      size="small"
      prefix-icon="el-icon-search"
      style="margin-bottom: 3px; height: 40px"
      clearable
    />
    <div :id="divId" class="tree-div1" style="flex: 1">
      <el-tree
        ref="tree"
        node-key="uid"
        :data="dataTree"
        :props="defaultProps"
        :indent="8"
        highlight-current
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        :show-checkbox="showSelect"
        :check-on-click-node="true"
        draggable
        default-expand-all
        @node-contextmenu="nodeRightClick"
        @node-expand="closeMenu"
        @node-collapse="closeMenu"
        @check="handleNodeClick"
      >
        <span
          slot-scope="{ node, data }"
          class="custom-tree-node"
          :class="data.typeName == '' ? 'text-warning' : ''"
        >
          <em :class="getNodeIcon(data) + ' tree_icon'"></em>
          <span
            :title="getNodeName(data)"
            :style="
              maxWidth > 0
                ? 'max-width:' + maxWidth + 'px'
                : 'max-width:calc(' + width + 'px - 77px);width: unset;'
            "
            class="tree-name ellipsis"
            v-html="getNodeName(data)"
          >
          </span>
        </span>
      </el-tree>
    </div>
  </div>
</template>

<script>
import { highLightTxt } from "@/utils/util";
export default {
  name: "ProjectTree",
  props: {
    project: Object,
    dataTree: Array,
    showSelect: Boolean,
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    maxWidth: { type: Number, default: 0 },
  },
  data() {
    return {
      option: "delete",
      showImportCabinet: false,
      overlapCabinetPower: false,
      treeNodeName: "",
      defaultProps: {},
      rightMenuClass: "dhub-content-menu-design" + (Math.random() + "").substr(3),
      rightMenuVisible: false,
      pageLoading: false,
      rightMenuList: [],
      rightSelectData: {},
      rightClickNode: {},
      newCount: 100,
      cabinetSourceType: null,
      oldName: "",
      editName: "",
      divId: "",
      showNodeTip: false,
      newShow: false,
      removeNodeParam: {},
      taskStatus: {},
      dragParentNode: {},
      addNodeStyle: 0,
      moverParam: {},
      isHavaCodeImage: false,
      erpCategoryFields: {},
      erpCabinetFields: {},
      isErpCategory: false,
      showTip: false,
      cabinetComplete: "该设备已完工！",

      isClearable: true, // 可清空（可选）
      valueId: [],

      treeData: [],
      defaultPropsOption: {
        children: "children",
        label: "name",
        id: "id",
      },
      expandOnClickNode: true,
      defaultExpandedKey: [],
      filterText: "",
      valueTitle: "",
      labels: "",
      selectData: [],

      curNode: {},
    };
  },
  computed: {
    // ...mapGetters(["tenantId", "tenantLoginId", "memberId", "nickName"]),
  },
  watch: {
    // 根据树节点名称筛选树
    treeNodeName(val) {
      this.$refs.tree.filter(val);
    },
    filterText(val) {
      this.$refs.treeOption.filter(val);
    },
    curNode() {
      console.log(this.curNode);
      console.log(this.$refs["selectTree" + this.curNode.id]);
      this.$refs["selectTree" + this.curNode.id].visible = true;
    },
  },
  created() {},
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      const name = data.cabinetCode ? data.cabinetCode : data.name;
      return name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    nodeRightClick(event, node, manualNode) {
      this.closeMenu();
      this.rightSelectData = node;
      this.rightClickNode = manualNode;
      this.setRightMenuStyle(event, this.rightMenuList.length, 26);
    },
    isCategory(style) {
      return this.GLOBAL.NODE_TYPE.category === style;
    },
    isCabinet(style) {
      return this.GLOBAL.NODE_TYPE.cabinet === style;
    },
    isDrawer(style) {
      return this.GLOBAL.NODE_TYPE.drawer === style;
    },
    getNodeIcon(data) {
      if (this.isCategory(data.style)) {
        return "el-icon-folder-opened";
      } else if (this.isCabinet(data.style)) {
        return "cabinet-icon";
      } else {
        return "other-icon";
      }
    },
    getValue(node, data) {},
    getNodeName(data) {
      const name = data.cabinetCode ? data.cabinetCode : data.name;
      if (this.treeNodeName && this.treeNodeName !== "") {
        return highLightTxt(name, this.treeNodeName);
      } else {
        return name ? name.replace(/</g, "&lt;").replace(/>/g, "&gt;") : "";
      }
    },
    closeMenu(event) {
      this.rightMenuVisible = false;
      document.removeEventListener("click", this.closeMenu);
    },
    setRightMenuStyle(event, menuCount, lineHeight) {
      this.rightMenuVisible = false;
      let top = event.clientY + 15;
      const left = event.clientX + 15;
      const menuHeight = menuCount * lineHeight;
      if (window.innerHeight < top + menuHeight) {
        top = top - 30 - menuHeight;
      }
      document.querySelector("." + this.rightMenuClass).style.top = top + "px";
      document.querySelector("." + this.rightMenuClass).style.left = left + "px";
      document.addEventListener("click", this.closeMenu);
      this.rightMenuVisible = true;
    },
    handleNodeClick(data) {
      this.closeMenu();
      this.$emit("handleNodeClick", data);
    },

    // 初始化值
    initHandle() {
      this.treeData = this.loadTreeData;
      this.isClearable = this.clearable;
      this.initScroll();
    },
    // 初始化滚动条
    initScroll() {
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll(".el-scrollbar .el-select-dropdown__wrap")[0];
        const scrollBar = document.querySelectorAll(".el-scrollbar .el-scrollbar__bar");
        scrollWrap.style.cssText = "margin: 5px; max-height: none; overflow: hidden;";
        scrollBar.forEach((ele) => (ele.style.width = 0));
      });
    },
    // 切换选项
    handleNodeClickOption(node, data) {
      this.curNode = data;
      if (node.parentId == null) {
        this.$message.warning("只能选择二级节点");
        return;
      }
      data.typeName = node.name;
      data.typeId = node.id;
      data.auxiliaryMinute = node.auxiliaryMinute;
      data.basicMinute = node.basicMinute;
    },
    // 清除选中
    clearHandle() {
      this.valueTitle = "";
      this.valueId = "";
      this.defaultExpandedKey = [];
      this.selectData.typeName = [];
      this.clearSelected();
      this.$emit("getValue", null, this.selectedData);
    },
    /* 清空选中样式 */
    clearSelected() {
      const allNode = document.querySelectorAll("#tree-option .el-tree-node");
      allNode.forEach((element) => element.classList.remove("is-current"));
    },
    filterNodeOption(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    visibleChange() {
      this.filterText = "";
      this.initHandle();
    },
    renderContent(h, { node, data, store }) {
      return (
        <span class="custom-tree-node">
          <span>{data.name}</span>
        </span>
      );
    },
    removeTreeNode(node, data) {
      const parent = node.parent;
      const children = parent.childNodes;
      const index = children.findIndex((d) => d.data.mid === data.mid);
      children.splice(index, 1);

      if (children.length === 0) {
        parent.isLeaf = true;
      }
    },
  },
};
</script>

<style lang="scss">
.project-tree {
  display: inline-block;

  .el-input--mini .el-input__inner {
    height: 22px !important;
    line-height: 22px !important;
  }

  .el-input--mini .el-input__icon {
    line-height: 22px;
  }
  // .text-warning {
  //   .el-input--mini .el-input__inner {
  //     background-color: #ffba00 !important;
  //   }
  // }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #fff !important;
    border-color: #ff9700 !important;
  }

  .el-checkbox__input.is-indeterminate .el-checkbox__inner:before {
    content: "";
    position: absolute;
    display: block;
    background-color: #ff9700;
    height: 2px;
    transform: scale(0.5);
    left: 0;
    right: 0;
    top: 5px;
  }

  .tree-table {
    float: right;
    width: 450px;
    display: flex;
    padding-right: 10px;
    .cell-80 {
      width: 70px;
    }
    .cell-100 {
      width: 90px;
    }
    .cell-120 {
      width: 110px;
    }
    .cell-150 {
      width: 110px;
    }
    .text-center {
      text-align: center;
    }
  }

  .el-menu-item {
    padding: 0 30px !important;
  }

  .tree-div1 {
    font-size: 13px;
    width: 100%;
    overflow: auto;
    border: 1px solid #dcdfe6;
    width: 100%;
    margin-top: 2px;
    overflow-x: auto;
    overflow-y: auto;
  }

  .mark-complete {
    height: 40px !important;
    line-height: 40px !important;
    border-bottom: 1px solid #dcdfe6;
    color: #4aae66 !important;
  }

  .tree-div1 .el-tree {
    min-width: 100%;
    display: inline-block !important;
  }

  .custom-tree-node {
    width: 100%;
    position: relative;
  }

  .tree-name {
    //max-width: 160px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }

  .tree-name-input {
    width: 130px;
  }

  .tree-name-input.el-input--mini .el-input__inner {
    height: 24px;
    line-height: 24px;
    padding: 0 5px;
  }

  .task-name {
    width: 160px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }

  .tree-user {
    width: 100px;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }

  .custom-tree-node {
    .hyperlink-icon {
      background-image: url("../../../assets/images/hyperlink1.png");
    }

    .cabinet-icon {
      background-image: url("../../../assets/images/cabinet_1.png");
    }

    .other-icon {
      background-image: url("../../../assets/images/project_other.png");
    }

    .tree_icon {
      display: inline-block;
      height: 16px;
      width: 18px;
      background-repeat: no-repeat;
      vertical-align: middle;
    }
  }

  .dhub-content-menu {
    position: fixed;
    background-color: #fff;
    font-size: 12px;
    color: #444040;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 1000;
    line-height: 26px;

    .el-menu-item {
      height: 26px;
      line-height: 26px;
    }

    .el-menu-item.is-active {
      color: #000;
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dhub-tree-popover1 {
  padding: 0;

  .el-menu-item {
    height: 26px;
    line-height: 26px;
  }

  .el-menu-item.is-active {
    color: #000;
  }
}

.text-theme1 {
  color: #ff9700;
}
.text-warning {
  color: red;
  background-color: #ffeed1 !important;
}
</style>
