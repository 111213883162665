<template>
  <div id="page-container" class="page-container">
    <!-- <div ref="toolbar" class="toolbar"></div> -->
    <div v-if="!tableDivShow" style="padding-top: 100px">
      <span>正在分派任务，请稍后 </span>
      <span style="color: red">{{ countTime }}</span>
      <span> s</span>
    </div>

    <div
      v-else
      ref="mainContent"
      style="display: flex; height: 570px; flex-direction: row; justify-content: space-between"
    >
      <schedule-assign-tree
        :data-tree="dataTree"
        :project="project"
        :height="390"
        :width="700"
        :max-width="120"
        :show-select="false"
        style="padding-right: 30px"
      ></schedule-assign-tree>
      <div
        style="border: 1px solid #dcdfe6; overflow-y: auto"
        class="lead-gante-gc-box"
        :style="{
          left: '400px',
          width: '900px',
        }"
      >
        <gante-gc
          ref="ganteGc"
          :start_time="startTime"
          :end_time="endTime"
          :th_data="thData"
          :level="level"
          :gante_data="ganteData"
        ></gante-gc>
      </div>
    </div>
  </div>
</template>
<script>
import ScheduleAssignTree from "./components/ScheduleAssignTree";
import Project from "@/api/project";
import SchedulingAssign from "@/api/scheduling-assign";
import { getProjectTreeFun, stringToDate } from "@/utils/util";
import ganteGc from "@/components/GanteTest/gante-gc";
import { resize } from "@/components/GanteTest/resize";
import { getAssignTaskList, scheduleAssignTask } from "@/api/assign-task";
export default {
  name: "ScheduleList",
  components: { ScheduleAssignTree, ganteGc },
  props: {
    project: { type: Object, default: {} },
    solutionId: { type: Number, default: 0 },
  },
  data() {
    return {
      query: {
        solutionId: 10,
      },
      countTime: 10,
      tableDivShow: false,
      tasks: [],
      mainHeight: 500,
      dataTree: [],
      productCategoryData: [],
      cabinetAssignMap: new Map(),
      ganteData: [],
      thData: {},
      cabinetList: [],
      startTime: 0,
      endTime: 0,
      level: 1,
      timeMode: 1,
      tableWidth: 0, // 表格的宽度
      taskList: [],
    };
  },
  mounted() {
    // let time = null;
    // this.$nextTick(function () {
    //   const ganteBox = document.getElementsByClassName("lead-gante-box")[0];
    //   if (time) {
    //     clearTimeout(time);
    //   }
    //   time = setTimeout(function () {
    //     resize.onelresize(ganteBox, function () {}, 100);
    //   });
    // });
  },
  methods: {
    startAssignTask() {
      const that = this;
      that.countTime = 10;
      scheduleAssignTask({ solutionId: this.solutionId }).then((res1) => {
        that.timer = setInterval(() => {
          if (that.countTime > 0) {
            that.countTime--;
          } else if (that.countTime === 0) {
            // getAssignTaskList({ solutionId: this.solutionId }).then((res) => {
            //   this.tasks = res.data;
            //   that.tableDivShow = true;
            //   clearInterval(that.timer);
            //   that.timer = null;
            // });
            this.getProjectTree();
          }
        }, 1000);
      });
    },

    async getProjectTree() {
      await this.getSchedulingAssign();
      const that = this;
      const params = {
        projectId: that.project.id,
        projectCreateDate: that.project.createTime,
      };
      const taskList = [];
      that.axios.post(Project.getProjectTree, params).then((res) => {
        that.tableDivShow = true;
        clearInterval(that.timer);
        that.timer = null;
        const data = JSON.parse(res);

        data.data.cabinetList.forEach((cabinet) => {
          const map = new Map();
          that.cabinetAssignMap[cabinet.id].forEach((item) => {
            if (item.parent_id == null) {
              map.set(item.processId, item.id);
            }
          });
          that.cabinetAssignMap[cabinet.id].forEach((element) => {
            const nowDate = new Date("2024-05-16T15:10:00");
            const timerStart = nowDate.getTime() + parseInt(element.start) * 60 * 1000;
            const timerEnd = timerStart + parseInt(element.duration) * 60 * 1000;
            const parentId = cabinet.id;
            // if (element.parentd != null) {
            //   parentId = map.get(element.procedureLibraryId);
            // }
            const item = {
              style: 15,
              name: element.label,
              user: element.user,
              cabinetCode: element.label + "(" + cabinet.cabinetCode + ")",
              startDate: this.formatMilliseconds(timerStart),
              endDate: this.formatMilliseconds(timerEnd),
              parentId: parentId,
              duration: element.duration,
            };
            data.data.cabinetList.push(item);
            taskList.push(item);
          });
        });
        that.cabinetList = data.data.cabinetList;
        that.taskList = taskList;
        that.load();
        this.loadTree(data.data);
      });
    },

    // 将毫秒数转换为日期、时、分和秒，并按照指定格式输出
    formatMilliseconds(milliseconds) {
      const date = new Date(milliseconds);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    loadTree(data) {
      if (data) {
        this.dataTree = getProjectTreeFun(
          data,
          this.GLOBAL.NODE_TYPE,
          this.project.name,
          this.project.code,
          false
        );
      }
    },

    async getSchedulingAssign() {
      const that = this;
      const params = {
        projectId: that.project.id,
        solutionId: that.solutionId,
      };
      return new Promise(function (resolve, reject) {
        that.axios.post(SchedulingAssign.getSchedulingAssign, params).then((res) => {
          that.cabinetAssignMap = res;
          resolve(res);
        });
      });
    },

    tableRowClassName({ row, rowIndex }) {
      return "row-selection";
    },
    tableIndex(index) {
      // 列表序号
      return this.query.page * this.query.size + index + 1;
    },
    productRowSelectChangerHandler() {},
    productRowClickHandler() {},

    load() {
      const thData = {
        index: { value: "序号", width: 50, showToast: false, listen_click: true },
        name: { value: "任务名称", width: 80, showToast: true, listen_click: true },
        executor: { value: "执行人", width: 80, shrink: false, showToast: true, edit: false },
        startTime: {
          value: "计划时间",
          width: 170,
          showToast: true,
          chooseTime: false,
          time_mode: 1,
        },
        status: { value: "状态", width: 80, shrink: false, showToast: false, edit: false },
      };
      this.thData = thData;
      const data = this.initTaskList(this.taskList, 1, { code: 1 });
      let begin = data[1];
      let end = data[2];
      if (!begin) {
        begin = new Date().getTime() - 1 * 24 * 3600 * 1000;
      }
      if (!end || end < begin + 30 * 24 * 3600 * 1000) {
        end = begin + 30 * 24 * 3600 * 1000;
      }
      let tableWidth = 10;
      for (const key in thData) {
        tableWidth += thData[key].width;
      }
      this.init(
        {
          ganteData: data[0],
          startTime: begin,
          endTime: end,
          thData: thData,
          height: "calc(100vh - 180px)",
          tableWidth: tableWidth,
        },
        true
      );
    },

    // 初始化
    init(params, mode = true) {
      const {
        ganteData,
        height,
        thData,
        tableWidth,
        startTime,
        endTime,
        open,
        timeMode,
        onEdit,
        onClick,
      } = params;

      if (ganteData !== undefined) {
        this.ganteData = ganteData;
      }
      if (height !== undefined) {
        this.height = height;
      }
      if (thData !== undefined) {
        this.thData = thData;
      }
      if (open !== undefined) {
        this.open = open;
      }
      if (timeMode !== undefined) {
        this.timeMode = timeMode;
      }

      if (onEdit !== undefined) {
        this.onEdit = onEdit;
      }

      if (onClick !== undefined) {
        this.onClick = onClick;
      }

      if (startTime !== undefined) {
        this.startTime = startTime;
      }
      if (endTime !== undefined) {
        this.endTime = endTime;
      }
      this.$nextTick(function () {
        /* ----------  此处调用gante-gc.vue中的init方法重新初始化时间轴的mode  ---------- */
        const data = this.$refs.ganteGc.init(this.timeMode);
        const that = this;
        setTimeout(function () {
          that.$refs.ganteGc.init(that.timeMode);
        });
        this.formatGanteData(this.ganteData, data.time, data.start_time);
        // 重新渲染所有时间进度
        this.ganteData = JSON.parse(JSON.stringify(this.ganteData));
        if (mode) {
          if (tableWidth !== undefined) {
            this.tableWidth = tableWidth;
          } else {
            const _width = this.$refs.ganteBox.clientWidth;
            this.tableWidth = _width / 2;
          }
        }
      });
    },

    initTaskList(taskList, level, code) {
      let max = new Date().getTime();
      let min = max;
      const re = [];
      const that = this;
      taskList.forEach((item) => {
        const start = stringToDate(item.startDate).getTime();
        const end = stringToDate(item.endDate).getTime();
        min = min < start ? min : start;
        max = max > end ? max : end;
        item.schedule = 100;
        re.push(that.setItemParam(item, code, start, end, level));
      });
      if (re.length > 0) {
        re[re.length - 1].border = 1;
      }
      console.log("LKJHH");
      console.log(max - min);
      return [re, min, max];
    },

    setItemParam(item, code, start, end, level) {
      return {
        gunter_id: item.id,
        params: {
          index: code.code++,
          name: item.name,
          number: 0,
          cabinetCode: item.cabinetCode,
          startTime:
            (item.hasOwnProperty("startDate")
              ? item.startDate.slice(0, 10).replaceAll("-", ".")
              : "") +
            "-" +
            (item.hasOwnProperty("endDate") ? item.endDate.slice(0, 10).replaceAll("-", ".") : ""),
          cls: "",
        },
        start_time: start,
        end_time: end,
        cabinetCode: item.cabinetCode,
        schedule: item.schedule,
        level: level,
        nameColor: "#1b82db",
        bg_color: "#a2c7f3",
        bg_color1: "#3287ff",
        children: [],
      };
    },

    // 对数据进行处理
    formatGanteData(ganteData, time, startTime) {
      for (const i of ganteData) {
        if (i.start_time || i.end_time) {
          i.left = (i.start_time - startTime) / time;
          if (i.end_time && i.end_time >= i.start_time && i.start_time) {
            const endDate = new Date(i.end_time);
            i.width =
              (new Date(
                endDate.getFullYear(),
                endDate.getMonth(),
                endDate.getDate(),
                endDate.getHours(),
                endDate.getMinutes(),
                endDate.getSeconds()
              ) -
                i.start_time) /
              time;
            i.width1 = (i.width / 100) * i.schedule;
          } else {
            i.width = 0;
            i.width1 = 0;
          }
        }

        /* ----------  实际时间轴的位置生成  ---------- */
        if (i.actual_start_time || i.actual_end_time) {
          i.left1 = (i.actual_start_time - startTime) / time;
          if (
            i.actual_end_time &&
            i.actual_end_time >= i.actual_start_time &&
            i.actual_start_time
          ) {
            i.width1 =
              (new Date(
                new Date(i.actual_end_time).getFullYear(),
                new Date(i.actual_end_time).getMonth(),
                new Date(i.actual_end_time).getDate(),
                new Date(i.actual_end_time).getHours(),
                new Date(i.actual_end_time).getMinutes(),
                new Date(i.actual_end_time).getSeconds()
              ) -
                i.actual_start_time) /
              time;
          } else {
            i.width1 = 0;
          }
        }

        if (i.open === undefined) {
          this.$set(i, "open", this.open);
        }

        if (i.children) {
          this.formatGanteData(i.children, time, startTime);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.workshop-content {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 210px);
  border: 1px solid #e9ecf0;
}

.lead-gante-gc-box {
  overflow: auto;
  top: 0;
  border-left: 5px solid transparent;
  height: 100%;
  box-sizing: border-box;
  padding-right: 2px;
}
</style>

<style></style>
