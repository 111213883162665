import axios from '@/libs/api.request'



// 获取排程后的任务列表
export const getScheduleJobAllocationList = (params) => {
  return axios.request({
    url: 'solution/schedule/task',
    method: 'get',
    params
  })
}

// 开始排程
export const scheduleJob = (params) => {
  return axios.request({
    url: 'solution/solve/task',
    method: 'get',
    params
  })
}


