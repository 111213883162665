import axios from '@/libs/api.request'



// 获取分派后的任务列表
export const getAssignTaskList = (params) => {
  return axios.request({
    url: 'assign',
    method: 'get',
    params
  })
}

// 开始分派任务
export const scheduleAssignTask = (params) => {
  return axios.request({
    url: 'assign/solve',
    method: 'get',
    params
  })
}


