<template>
  <div id="page-container" class="schedule-content page-container">
    <el-steps
      :space="400"
      :active="activeStep"
      finish-status="success"
      align-center
      style="width: 100%; padding: 10px"
      class="common-display-row-align-center"
    >
      <el-step title="工艺匹配"></el-step>
      <!-- <el-step title="排程"></el-step>
      <el-step title="任务分派"></el-step> -->
    </el-steps>
    <div v-if="activeStep == 0">
      <process-matching ref="processMatch" :project="project"></process-matching>
    </div>
    <div v-if="activeStep == 1" class="step-item">
      <div v-if="!ganttShow" style="padding-top: 100px">
        <span>正在排程，请稍后 </span>
        <span style="color: red">{{ countTime }}</span>
        <span> s</span>
      </div>

      <schedule-list
        v-else
        ref="scheduleList"
        :project="project"
        :solution-id="solutionId"
      ></schedule-list>
    </div>
    <div v-if="activeStep == 2" class="step-item">
      <task-list ref="taskList" :project="project" :solution-id="solutionId"></task-list>
    </div>
    <el-row style="padding-top: 10px">
      <el-col :span="24">
        <el-button type="primary" @click="submit">确认</el-button>
        <!-- <el-button v-if="activeStep != 0" type="primary" @click="backStep">上一步</el-button>
        <el-button v-if="activeStep != 3" type="primary" @click="nextStep">下一步</el-button>
        <el-button v-if="activeStep == 3" type="primary" @click="confirm">确认分派</el-button> -->
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Project from "@/api/project";
import ScheduleList from "./schedule-list";
import { scheduleJob } from "@/api/schedule";
import TaskList from "./task-list";
import ProcessMatching from "./components/ProcessMatching";
export default {
  name: "Workshop",
  components: { ScheduleList, TaskList, ProcessMatching },
  props: {
    projectId: Number,
  },
  data() {
    return {
      query: {
        kw: "",
        page: 0,
        size: 10,
        total: 0,
      },

      mainHeight: 300,

      project: {},

      activeStep: 0,

      dataTree: [],

      tabledata: [],

      productCategoryData: [],

      processRoutedata: [],

      queryBom: {
        oldPage: 1,
        page: 1,
        pageSize: 50,
        searchInfo: "",
        orderByColumn1: "id",
        isAsc1: "asc",
      },

      selectedData: {},

      cabinetList: [],

      processCategoryCabinetList: [],

      // step:2
      routeCabinetList: [],

      processNewListData: [],

      countTime: 10,

      timer: null,

      ganttShow: false,

      cabinetMap: null,

      assignShow: false,

      solutionId: 0,

      // 开发：27782，测试：11147
      // projectId: 27782,
    };
  },
  watch: {
    ganttShow(to) {
      const that = this;
      setTimeout(function () {
        that.$refs.scheduleList.onOpen();
      }, 100);
    },
    assignShow(to) {
      const that = this;
      setTimeout(function () {
        that.$refs.scheduleList.onOpen();
      }, 100);
    },
  },
  mounted() {
    this.getProject();
  },
  methods: {
    getProject() {
      const that = this;
      that.axios.post(Project.getProject, { projectId: that.projectId }).then((data) => {
        that.project = JSON.parse(data).data;
        setTimeout(function () {
          that.$refs.processMatch.onOpen();
        }, 100);
      });
    },

    getTotalHours(cabinetList) {
      if (cabinetList.length === 0) {
        return 0;
      }
      let count = 0;
      cabinetList.forEach((item) => {
        count += this.cabinetMap.get(item.cabinetId).count;
      });
      const cabinet = this.cabinetMap.get(cabinetList[0].cabinetId);
      return (cabinet.auxiliaryMinute + cabinet.basicMinute) * count;
    },

    getTotalCabinetCount(cabinetList) {
      if (cabinetList.length === 0) {
        return 0;
      }
      let count = 0;
      cabinetList.forEach((item) => {
        count += this.cabinetMap.get(item.cabinetId).count;
      });
      return count;
    },
    nextStep() {
      if (this.activeStep === 0) {
        // this.insertProcessCategoryCabinets(arr);
        this.activeStep = 1;
        const that = this;
        scheduleJob({ solutionId: that.solutionId, startTime: 1715843400 }).then((res) => {
          that.timer = setInterval(() => {
            if (that.countTime > 0) {
              that.countTime--;
            } else {
              that.ganttShow = true;
              clearInterval(that.timer);
              that.timer = null;
            }
          }, 1000);
        });
      } else if (this.activeStep === 2) {
        this.ganttShow = false;
        this.activeStep = 3;
        const that = this;
        setTimeout(function () {
          that.$refs.taskList.startAssignTask();
        }, 100);
      }
    },
    insertProcessCategoryCabinets(data) {
      this.axios
        .post(Project.insertProcessCategoryCabinet, {
          projectId: this.project.id,
          processCategoryCabinetList: data,
        })
        .then((data) => {
          this.solutionId = data;
          this.getRouteCabinet();
        });
    },
    backStep() {
      if (this.activeStep === 2) {
        this.ganttShow = false;
      } else if (this.activeStep === 3) {
        const that = this;
        that.ganttShow = true;
      }
      this.activeStep -= 1;
    },
    isCategory(data) {
      return data && data.style === 100;
    },
    getRouteCabinet() {
      const that = this;
      this.axios
        .post(Project.getRouteCabinet, {
          projectId: this.project.id,
          solutionId: this.solutionId,
        })
        .then((data) => {
          // that.routeCabinetList = data;
          console.log(that.routeCabinetList);
          data.forEach((element) => {
            const tempData = [];
            const map = new Map();
            element.processFlowInfoList.forEach((item) => {
              if (item.belongingProcess == null) {
                tempData.push(item);
                map.set(item.procedureLibraryId, []);
              }
            });
            element.processFlowInfoList.forEach((item) => {
              if (item.belongingProcess != null) {
                const arr = map.get(item.belongingProcess);
                arr.push(item);
                map.set(item.belongingProcess, arr);
              }
            });
            tempData.forEach((item) => {
              item.children = map.get(item.procedureLibraryId);
              let auxiliaryMinutes = 0;
              let basicMinute = 0;
              map.get(item.procedureLibraryId).forEach((item) => {
                auxiliaryMinutes += item.auxiliaryMinute;
                basicMinute += item.basicMinute;
              });
              item.auxiliaryMinute = auxiliaryMinutes;
              item.basicMinute = basicMinute;
            });
            element.processFlowInfoList = tempData;
          });
          that.routeCabinetList = data;
        });
    },
    confirm() {
      this.$emit("close", this.solutionId);
    },
    submit() {
      this.$emit("close", this.$refs.processMatch.processData);
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-content {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 120px);
  border: 1px solid #e9ecf0;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.project-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  font-size: 24px;
}
.common-display-row-align-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.process-category-content {
  ::v-deep .row-expand-unhas .el-table__expand-icon > .el-icon {
    display: none;
  }

  ::v-deep .row-expand-unhas .el-table__expand-icon {
    visibility: hidden !important;
  }
}

.step-item {
  display: flex;
  height: 590px;
  width: 100%;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
}
</style>

<style></style>
