<template>
  <div class="lead-ganteview-content">
    <div v-for="(item, index) in gante_data" :key="item.gunter_id" class="lead-ganteview-ones">
      <div v-if="item.level === level" class="lead-ganteview-content-one">
        <div style="position: relative">
          <div
            :data-toast="index"
            :style="{
              width: item.width ? item.width + 'px' : 0,
              left: item.left ? item.left + 'px' : 0,
              background: item.bg_color ? item.bg_color : '#a2c7f3',
            }"
            class="lead-ganteview-item"
            @mousemove="showToast"
            @mouseleave="hideToast"
            @click="openItemContentPage(item)"
          />
          <div
            style="font-size: 14px"
            :style="{
              position: 'absolute',
              left: item.left != null ? item.left + item.width + 5 + 'px' : '45px',
            }"
          >
            {{ item.cabinetCode }}
          </div>
        </div>
        <div style="position: relative">
          <div
            :data-toast="index"
            :style="{
              width: item.width1 ? item.width1 + 'px' : 0,
              left: item.left ? item.left + 'px' : 0,
              top: '0',
              background: item.bg_color1 ? item.bg_color1 : '#3287ff',
              lineHeight: '30px',
            }"
            class="lead-ganteview-item"
            @mousemove="showToast"
            @mouseleave="hideToast"
            @click="openItemContentPage(item)"
          />
        </div>
      </div>
      <ganteview-item
        v-if="item.children"
        :th_data="th_data"
        :gante_data="item.children"
        :level="level"
        @openGanteViewItem="openGanteViewItem"
      />
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "GanteviewItem",
  props: {
    gante_data: Array,
    th_data: Object,
    level: Number,
  },
  data() {
    return {};
  },
  mounted() {},
  updated() {
    // console.log('gante-gc-item.vue updated')
  },
  methods: {
    showToast(e) {
      let target = e.target;
      if (e.target.nodeName == "SPAN") {
        target = e.target.parentNode;
      }
      const data = this.gante_data[target.dataset.toast];
      let x = e.clientX + 15;
      let y = e.clientY + 15;
      if (data.params) {
        let toast = document.createElement("div");
        let add_toast = true;
        toast.setAttribute("class", "lead-gante-toast");
        if (document.getElementsByClassName("lead-gante-toast")[0]) {
          toast = document.getElementsByClassName("lead-gante-toast")[0];
          toast.style.display = "block";
          add_toast = false;
        }
        let list = "";
        for (const i in this.th_data) {
          if (data.params[i] != undefined && this.th_data[i].showToast) {
            list += "<p>" + this.th_data[i].value + "：" + data.params[i] + "</p>";
          }
        }
        if (window.innerWidth < x + 250) {
          x = x - 280;
        }
        if (window.innerHeight < x + 120) {
          y = y - 145;
        }
        toast.innerHTML = list;
        toast.style.left = x + "px";
        toast.style.top = y + "px";
        if (add_toast) {
          document.getElementsByTagName("body")[0].appendChild(toast);
        }
      }
    },
    hideToast() {
      if (document.getElementsByClassName("lead-gante-toast")) {
        document.getElementsByClassName("lead-gante-toast")[0].style.display = "none";
      }
    },
    openGanteViewItem(data) {
      // 调用父页面gante-gc方法openContentPage
      this.$emit("openContentPage", data);
    },
    openItemContentPage(data) {
      // 项目未开启甘特图背景颜色'#e4e4e4'
      if (data.bg_color == "#e4e4e4") {
        return;
      }
      // 只调用自身页面ganteview-item方法openGanteViewItem，会导致有些甘特图不能跳转到父页面，所以添加了直接调用父页面方法openContentPage
      this.$emit("openGanteViewItem", data.gunter_id);
      this.$emit("openContentPage", data.gunter_id);
    },
  },
};
</script>
<style scoped lang="scss">
.lead-ganteview-content {
  position: relative;

  .lead-ganteview-content-one {
    /* 每一条时间跨度的容器高度 */
    height: 33px;
    box-sizing: border-box;
    line-height: 32px;
  }

  .lead-ganteview-item {
    position: absolute;
    /* 每一条进度的高度值 */
    height: 10px;
    border-radius: 5px;
    background: #00b0ff;
    margin-top: 10px;
    cursor: pointer;
  }

  .lead-ganteview-item.has-child {
    height: 15px;
  }
}
</style>
