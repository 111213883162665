var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lead-ganteview-content"},[_vm._l((_vm.gante_data),function(item,index){return _c('div',{key:item.gunter_id,staticClass:"lead-ganteview-ones"},[(item.level === _vm.level)?_c('div',{staticClass:"lead-ganteview-content-one"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"lead-ganteview-item",style:({
            width: item.width ? item.width + 'px' : 0,
            left: item.left ? item.left + 'px' : 0,
            background: item.bg_color ? item.bg_color : '#a2c7f3',
          }),attrs:{"data-toast":index},on:{"mousemove":_vm.showToast,"mouseleave":_vm.hideToast,"click":function($event){return _vm.openItemContentPage(item)}}}),_c('div',{staticStyle:{"font-size":"14px"},style:({
            position: 'absolute',
            left: item.left != null ? item.left + item.width + 5 + 'px' : '45px',
          })},[_vm._v(" "+_vm._s(item.cabinetCode)+" ")])]),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"lead-ganteview-item",style:({
            width: item.width1 ? item.width1 + 'px' : 0,
            left: item.left ? item.left + 'px' : 0,
            top: '0',
            background: item.bg_color1 ? item.bg_color1 : '#3287ff',
            lineHeight: '30px',
          }),attrs:{"data-toast":index},on:{"mousemove":_vm.showToast,"mouseleave":_vm.hideToast,"click":function($event){return _vm.openItemContentPage(item)}}})])]):_vm._e(),(item.children)?_c('ganteview-item',{attrs:{"th_data":_vm.th_data,"gante_data":item.children,"level":_vm.level},on:{"openGanteViewItem":_vm.openGanteViewItem}}):_vm._e()],1)}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }